$color1: #372d78;
$color2: #005aff;
$white: #fff;
$black: #000;
$green: #27CCBA;
$grey-light: #f0f1f7;
$grey-light2: #d7d9e0;
$grey-light3: #ced4da;
$grey: #a5a8b8;
$grey-dark: #6C757D;
$error: #cc5965;
$orange: #ff6e64;

@import 'app';

nav {
    .router-link-active {
        color: $color2 !important;

    }
}

.nav-item a .bottom-bar {
    background-color: $color2;
}

.page-item.active .page-link {
    background-color: $color2;
    border-color: $color2;
}
footer {
    .dc {
        display: none;
    }
}
